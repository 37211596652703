<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.reports")
        }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight()">
        <v-list two-line subheader>
          <v-list-item v-if="$allowed('srs:incidents')">
            <v-list-item-icon>
              <v-icon color="green">mdi-file-excel</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("srs.incidents") }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("srs.downloadDescription") }}
              </v-list-item-subtitle>
              <!-- <div style="max-width: 200px;">
                <v-select
                  :value="batchSize"
                  :items="[1000, 2000, 3000, 4000, 5000, 6000, 7000]"
                  label="Reports per batch"
                  @input="batchSize = parseInt($event)"
                  style="font-size: 10pt"
                ></v-select>
              </div>

              <div class="d-flex justify-end">
                <div style="text-align: end">
                  <v-btn
                    v-for="i in rnaList"
                    :key="i"
                    small
                    class="mr-1 mb-1"
                    color="primary"
                    @click="downloadSrs(i)"
                    depressed
                    :loading="getDownloadingState('srs')"
                  >
                    {{ getSrsFromPage(i) }} - {{ getSrsToPage(i) }}
                  </v-btn>
                </div>
              </div> -->
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                class="ma-1"
                color="primary"
                :disabled="loadingReports.incidents"
                @click="downloadIncidents()"
                depressed
                :loading="
                  loadingReports.incidents || getDownloadingState('incidents')
                "
              >
                Download</v-btn
              >
            </v-list-item-action>

          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item v-if="$allowed('reports:organizations')">
            <v-list-item-icon>
              <v-icon color="green">mdi-file-excel</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("reports.orgs") }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("reports.orgsDescription") }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                small
                class="ma-1"
                color="primary"
                @click="
                  download({
                    action: 'orgs',
                    file: 'organizations.xlsx',
                  })
                "
                depressed
                :loading="getDownloadingState('orgs')"
              >
                Download</v-btn
              >
            </v-list-item-action>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item
            :disabled="loadingReports.users"
            v-if="$allowed('reports:users')"
          >
            <v-list-item-icon>
              <v-icon color="green">mdi-file-excel</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("reports.users") }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("reports.usersDescription") }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                small
                class="ma-1"
                color="primary"
                :disabled="loadingReports.userLog"
                @click="downloadUsers()"
                depressed
                :loading="
                  loadingReports.userLog || getDownloadingState('users')
                "
              >
                Download</v-btn
              >
            </v-list-item-action>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item v-if="$allowed('reports:datatypes')">
            <v-list-item-icon>
              <v-icon color="green">mdi-file-excel</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t("reports.dataTypes")
              }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("reports.dataTypesDescription") }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                small
                class="ma-1"
                color="primary"
                @click="download({ action: 'types', file: 'data-types.xlsx' })"
                depressed
                :loading="getDownloadingState('types')"
              >
                Download</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UsersLogFilterDialog from "./filters/UsersLogFilterDialog";
import UsersFilterDialog from "./filters/UsersFilterDialog";

export default {
  name: "Reports",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UsersLogFilterDialog,
    // eslint-disable-next-line vue/no-unused-components
    UsersFilterDialog,
  },
  data() {
    return {
      orgs: [],
      orgId: 0,
      count: 0,
      batchSize: 7000,
      loadingReports: {
        incidents: false,
        userLog: false,
        users: false,
      },
    };
  },
  async mounted() {
    // const { organizationId } = this.$getUserInfo();
    // this.orgId = organizationId;
    // if (this.$hasRole("Administrator")) {
    //   this.fetchSrsCount().then((count) => (this.count = count));
      this.loadOrganizations().then((data) => {
        this.orgs = [{ id: -1, name: "All", acronym: "All" }, ...data];
        this.loadingReports.users = false;
        this.loadingReports.userLog = false;
      });
    // } else {
      // this.fetchSrsCount(this.orgId).then((count) => (this.count = count));
      // this.loadOrganizations().then((data) => {
      //   this.orgs = data.filter((f) => f.id === this.orgId);
      //   this.loadingReports.userLog = false;
      // });
    // }
  },
  computed: {
    ...mapGetters("downloads", ["getDownloadingState"]),
    rnaList() {
      if (this.count <= this.batchSize) {
        return Array.from({ length: 1 }, (_, index) => index + 1);
      } else {
        return Array.from(
          { length: Math.ceil(this.count / this.batchSize) },
          (_, index) => index + 1
        );
      }
    },
  },
  methods: {
    ...mapActions("downloads", ["download"]),
    ...mapActions("organizations", ["loadOrganizations"]),
    ...mapActions("users", ["loadUsers"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),
    ...mapActions("srs", ["fetchSrsCount"]),

    getSrsFromPage(i) {
      return (i - 1) * this.batchSize + 1;
    },
    getSrsToPage(i) {
      const to = i * this.batchSize;
      return to < this.count ? to : this.count;
    },
    async downloadIncidents() {
      this.download({
        ctr: "si",
        action: "download",
        file: "incidents.xlsx",
      });
    },

    // async downloadSrs(i) {
    //   this.download({
    //     action: "srs/by",
    //     file: `srs-reports(${this.getSrsFromPage(i)}-${this.getSrsToPage(
    //       i
    //     )}).xlsx`,
    //     params: {
    //       organization: this.$hasRole("Administrator")
    //         ? -1
    //         : this.$getUserInfo().organizationId,
    //       page: i - 1,
    //       size: this.batchSize,
    //       sort: "id,asc",
    //     },
    //   });
    // },

    async downloadUsers() {
      const dialog = {
        title: this.$t("general.filter"),
        component: UsersFilterDialog,
        params: {
          item: {
            orgId: this.orgId,
            orgs: this.orgs,
            selectedUsers: [],
          },
          body: this.$t("messages.searchCriteria"),
        },
        width: 450,
        actions: [
          {
            text: this.$t("general.okay"),
            click: ({ item }) => {
              this.download({
                action: "users",
                file: "users.xlsx",
                params: {
                  organizationId: item.orgId,
                  users: item.selectedUsers.map((m) => m.username),
                },
              });
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: this.$t("general.cancel"),
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },

    async downloadUsersLog() {
      const dialog = {
        title: this.$t("general.filter"),
        component: UsersLogFilterDialog,
        params: {
          item: {
            orgId: this.orgId,
            orgs: this.orgs,
            selectedUsers: [],
            from: null,
            to: null,
          },
          body: this.$t("messages.searchCriteria"),
        },
        width: 450,
        actions: [
          {
            text: this.$t("general.okay"),
            click: ({ item }) => {
              this.download({
                action: "users-log",
                file: "users-log.xlsx",
                params: {
                  organizationId: item.orgId,
                  users: item.selectedUsers.map((m) => m.username),
                  from: item.from,
                  to: item.to,
                },
              });
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: this.$t("general.cancel"),
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },
  },
};
</script>
