<template>
  <v-container>
    <p>{{ params.body }}</p>
    <v-form ref="form">
      <v-select
        v-model="params.item.orgId"
        :items="params.item.orgs"
        item-text="name"
        item-value="id"
        :label="$t('route.org')"
        dense
        :readonly="!$hasRole('Administrator')"
      ></v-select>
      <v-combobox
        v-model="params.item.selectedUsers"
        :items="getUsersOfSelectedOrg"
        item-text="username"
        item-value="id"
        hide-selected
        :label="$t('route.users')"
        multiple
        append-icon="mdi-clipboard-list"
        dense
      >
        <template v-slot:selection="{ attrs, item, select, selected }">
          <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            small
            class="pl-1 pr-1 ml-0 mr-1 mb-1"
            label
            @click="select"
            @click:close="removeFrom(item)"
          >
            <strong>{{ item.username }}</strong>
          </v-chip>
        </template>
      </v-combobox>
      <v-row>
        <v-col>
          <date-selector
            v-model="params.item.from"
            hide-icon
            :label="$t('general.from')"
          />
        </v-col>
        <v-col>
          <date-selector
            v-model="params.item.to"
            hide-icon
            :label="$t('general.to')"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import DateSelector from "@/common/components/DateSelector";
export default {
  name: "UsersLogFilterDialog",
  components: { DateSelector },
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      value: false,
    };
  },
  computed: {
    getUsersOfSelectedOrg() {
      const org =
        this.params.item.orgId !== -1
          ? this.params.item.orgs.find((f) => f.id === this.params.item.orgId)
          : null;
      return org ? org.users : [];
    },
  },
  methods: {
    removeFrom(item) {
      const coll = this.params.item.selectedUsers;
      coll.splice(coll.indexOf(item), 1);
      this.params.item.selectedUsers = [...coll];
    },
  },
};
</script>
